<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Type"
          >
            <b-form-select
              v-model="active"
              :options="[
                { value: null, text: 'All' },
                { value: true, text: 'Active' },
                { value: false, text: 'Non-Active' },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            type="button"
            @click.prevent="show"
          >Show</b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            Filter
          </b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button>
        </template>
        <template #cell(status)="data">
          <span
            class="badge "
            :class="data.item.status == 'request'?'bg-danger':'bg-warning'"
          >
            {{ data.item.status }}
          </span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,

    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      dataurl:"/maintenance",
      baseroute:"maintenance",
      title:"Perawatan",
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'description', label: 'Deskripsi', sortable: true },
        { key: 'plan_km', label: 'KM Rencana', sortable: true },
        { key: 'plan_date', label: 'Tanggal Rencana', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'plan_cost', label: 'Rencana Biaya', sortable: true },
        { key: 'realisation_date', label: 'Tanggal Realisasi', sortable: true },
        { key: 'realisation_cost', label: 'Biaya Realisasi', sortable: true },
        { key: 'realisation_km', label: 'Km Realisasi', sortable: true }
      ],
      showFilter : false,
      filter :[],
      active:null,
    }
  },
  methods:{
    show(){
      // this.filter['filter[active]']=this.active
      // this.filter['filter[region_id]']=this.region.id
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.selectedCheckedBox = []
      this.active = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
